import init from "../vbcn";

const vbcnOptions = {
    notice: {
        ignoredPages: [
            '/no-data'
        ],
        ignoredHashes: [
            '#hide-my-stuff',
        ],
        showOverlayOnInit: false,
        declineMode: 'session',
        centerMode: false,
        useBackdrop: false,
        analytics: {
            addInfo: true,
        }
    },
    texts: {
        "de": {
            explanationText: "Nach Ihrer Zustimmung verwenden wir Cookies um die Anzeige zu optimieren. Zur Analyse der Zugriffe auf unsere Website verwenden wir Google Analytics. Außerdem binden wir Scripte von facebook und twitter ein. Dabei können personenbezogenen Daten an diese Anbieter übermittelt werden. Bitte klicken Sie <a href='datenschutz.php'>hier</a>, um Informationen zum Datenschutz zu erhalten.",
            configuration_explanation_headline: "Was ist ein Cookie?",
            configuration_explanation_text: "Unter <strong>Cookie</strong> versteht man eine kleine <strong>Datei</strong>, beim Besuch von Internetseiten auf Deinem Computer oder Smartphone gespeichert wird. Besuchst Du die Website zu einem späteren Zeitpunkt wieder, wird der gespeicherte Cookie an den Webserver übertragen.",
        },
        "en": {
            explanationText: "On your consent cookies are set to optimize your experience on our website. \n\n" +
                "We use [[ Google Analytics / Matomo Analytics ]] to analyze the usage  of our website. \n\n" +
                "We embed scripts from these third party service providers: [[ YouTube, Vimeo, Google Maps, Mapbox, Facebook, Twitter, Instagram, ... ]] \n\n" +
                "On activation of one or more of these services personal data might be forwarded to these partners. Further information you find <a href='/datenschutz'>here</a> in our privacy statement.",
            configuration_explanation_headline: "What is a cookie?",
            configuration_explanation_text: "A <strong>cookie</strong> is a small <strong>data file</strong> that is stored when visiting a website on your computer or smartphone. As a result, the stored cookie will be transmitted to the web server each time you visit the website again.",
        },
        "it": {
            explanationText: "Con il vostro consenso, utilizziamo cookie per ottimizzare la visualizzazione. \n\n" +
                "Per l'analisi degli accessi al nostro sito web utilizziamo [[ Google Analytics / Matomo Analytics ]]. \n\n" +
                "Inoltre facciamo uso anche di script di [[ YouTube, Vimeo, Google Maps, Mapbox, Facebook, Twitter, Instagram, ...]]. \n\n " +
                "È possibile quindi che dei dati personali vengano trasmessi a tali offerenti. <a href='/datenschutz'>Cliccate quix</a> per ricevere informazioni sulla protezione dei dati.",
            configuration_explanation_headline: "What is a cookie?",
            configuration_explanation_text: "A <strong>cookie</strong> is a small <strong>data file</strong> that is stored when visiting a website on your computer or smartphone. As a result, the stored cookie will be transmitted to the web server each time you visit the website again.",
        }
    },
    sets: {
        "de": [
            {
                id: 'mandatory',
                name: 'Notwendige Cookies',
                description: 'Dieses Cookie ermöglichen das Speichern Ihrer Cookie-Einstellungen. Es speichert keine personenbezogenen Daten. <br/>Name: Cookiesettings <br/>Zweck: Speichern der Cookie-Einstellungen <br/>Cookie Name: cookiesettings (Laufzeit: 30 Tage)',
                mandatory: true,
                mandatory_text: "Diese Cookies sind notwendig, um Ihre Einstellungen zu speichern."
            }, {
                id: 'analytics',
                name: 'Matomo Analytics',
                description: 'Um unser Angebot und unsere Webseite weiter zu verbessern, erfassen wir anonymisierte Daten für Statistiken und Analysen. Mithilfe dieser Cookies können wir beispielsweise die Besucherzahlen und den Effekt bestimmter Seiten unseres Web-Auftritts ermitteln und unsere Inhalte optimieren. Wir verwenden hierzu das Analysetool Matomo. In lokaler Installation auf unserem eigenen dezidierten Serverspeicher. <br/>Name: Matomo, </br>Zweck: Statistische Analyse <br/>Cookie Name: _pk_id (Cookie Laufzeit: 13 Monate)<br />Cookie Name: _pk_ses (Cookie Laufzeit: 30 Minuten)<br />Cookie Name: _pk_testcookie (Cookie Laufzeit: Sitzungsende)'
            }, {
                id: 'maps',
                name: 'Mapbox',
                description: 'Um unser Büro auf einer Karte von München anzuzeigen, setzen wir die Kartenanwendung des externen Dienstes Mapbox ein. <br/>Die Datenschutzerklärung von Mapbox finden Sie hier: <a href="https://www.mapbox.com/legal/privacy/">https://www.mapbox.com/legal/privacy/</a>'
            }
        ],
        "en": [
            {
                id: 'mandatory',
                name: 'Notwendige Cookies',
                description: 'Diese Cookies sind für die einwandfreie Funktion und das Design unserer Seiten nötig. Sie speichern keine personenbezogenen Daten.',
                mandatory: true,
                mandatory_text: "Diese Cookies sind notwendig, um Ihre Einstellungen zu speichern."
            }, {
                id: 'analytics',
                name: 'Matomo Analytics',
                description: 'Diese Skripte und Cookies werden eingebunden, um mehr über die Besucher unserer Website zu erfahren - wie zum Beispiel Herkunft, Bildschirmauflösung oder verwendeter Browser.'
            }, {
                id: 'maps',
                name: 'Mapbox',
                description: 'Diese Skripte und Cookies sind nötig, um die Karten des Dienstes darzustellen. Durch das Aktivieren können personenbezogene Daten an [[ Google / Mapbox ]] übertragen werden.'
            }
        ]
    }
}

export function initCookieNotice() {

    window.myVBCN = init(vbcnOptions);

}
