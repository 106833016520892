'use strict';

import {initCookieNotice} from "./components/cookie-notice";
import {initHeaderOnScroll, initSmoothScrolling} from "./components/snippets";

// Doc Ready
document.addEventListener("DOMContentLoaded", function() {
    initCookieNotice();
    initSmoothScrolling();
    initHeaderOnScroll();
});
