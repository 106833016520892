import {debounce} from "./helpers";
import SmoothScroll from 'smooth-scroll';

const header = document.getElementById('header');
// const nav = document.getElementById('nav');
// const navTrigger = document.getElementById('nav-trigger');

function checkHeaderScroll() {
    if(window.scrollY > 100) {
        header.classList.add('header-scrolled');
        nav.classList.add('nav-scrolled');
    } else {
        header.classList.remove('header-scrolled');
        nav.classList.remove('nav-scrolled');
    }
}

// function openMenu() {
//     navTrigger.classList.add('is-triggered');
//     nav.style.maxHeight = nav.scrollHeight + 'px';
//     setTimeout(function(){
//         nav.style.maxHeight = 'initial';
//     }, 210);
// }
//
// function closeMenu() {
//     navTrigger.classList.remove('is-triggered');
//     nav.style.maxHeight = nav.scrollHeight + 'px';
//     setTimeout(function(){
//         nav.style.maxHeight = null;
//     }, 10);
// }
//
// function menuLogic() {
//     navTrigger.classList.contains('is-triggered') ? closeMenu() : openMenu();
// }
// // Nav triggers
// if(navTrigger) {
//     navTrigger.addEventListener('click', menuLogic);
// }

export function initHeaderOnScroll() {
    checkHeaderScroll();

    let checkHeaderScrollDebounced = debounce(checkHeaderScroll);
    window.addEventListener('scroll', checkHeaderScrollDebounced, false)
}

export function initSmoothScrolling() {
    new SmoothScroll('a[href*="#"]', {speed: 800});
}
